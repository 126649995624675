import React, { useEffect } from "react";
import { Link, Route, Routes, Outlet, useNavigate } from "react-router-dom";
import UserForm from "./UserForm";
import AllianceDuel from "./AllianceDuel";
import { useState } from "react";

const Home = () => {
  const [isloggedin, setIsloggedin] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (isloggedin) {
      navigate("/val-passes");
    } else {
      navigate("/");
    }
  }, [isloggedin]);

  const handleLogin = () => {
    // Perform password validation here
    if (password === "catafido") {
      setIsloggedin(true);
    } else {
      alert("Incorrect password");
    }
  };
  return (
    <div>
      <div className="my-4 w-100">
        {isloggedin ? (
          <>
            <div className="d-flex">
              <div className="">
                <Link to="/val-passes">
                  <img
                    className="menuitem img-fluid"
                    src="images/soaselect.jpg"
                  />
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="logocontainer">
              <div className="d-flex justify-content-center">
                <img width={400} height={"auto"} src="images/valhome.png" />
              </div>

              <div className="d-flex mt-2 ps-4 justify-content-center thelogin">
                <input
                  className="form-control me-2 mysubmit"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="btn btn-warning mysubmit"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {isloggedin && <Outlet />}
      {/* <Routes>
        <Route path="/zone-passes" element={<UserForm />} />
        <Route path="/alliance-duel" element={<AllianceDuel />} />
        <Route path="/" element={<UserForm />} />
      </Routes> */}
    </div>
  );
};

export default Home;
