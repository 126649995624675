import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import UserForm from "./components/UserForm";
import UserFormMini from "./components/UserFormMini";
import AllianceDuel from "./components/AllianceDuel";
import CreateGroups from "./components/CreateGroups";
import PlayersDashboard from "./components/PlayersDashboard";
import UpdateGroups from "./components/UpdateGroups";
import AdminDashboard from "./components/AdminDashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index path="/val-passes" element={<UserForm />} />
          {/* <Route path="/dfr-passes" element={<UserFormMini />} /> */}
          {/* <Route path="/alliance-duel" element={<AllianceDuel />} >
            <Route index element={<PlayersDashboard />} />
            <Route path="admin/*" element={<AdminDashboard />} >
              <Route index path="create-groupes" element={<CreateGroups />} />
              <Route path="update-groupes" element={<UpdateGroups />} />
            </Route>
          </Route> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
